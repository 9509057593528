import React, { useEffect } from 'react'
import ScreenHeading from '../../utilities/ScreenHeading/ScreenHeading'
import ScrollService from '../../utilities/ScrollService'
import Animations from '../../utilities/Animations'
import './AboutMe.scss'

export default function AboutMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return
    Animations.animations.fadeInScreen(props.id)
  }
  const fadeInSubscription = ScrollService.currentScreenFadeIn.subscribe(
    fadeInScreenHandler,
  )

  const SCREEN_CONSTSANTS = {
    description:
      'Sou Desenvolvedor VB6 a 17 anos, sempre trabalhei com aplicativos comerciais/Controle de Estoque/Financeiro e a 3 anos comecei com desenvolvimento back-end e agora estou cursando uma pós graduação para desenvolvimento Web.   ',
    highlights: {
      bullets: [
        'Desenvolvedor Full Stack',
        'Desenvolvedor .Net',
        'Desenvolvedor ReactJS ',
        'Desenvolvedor VB6',
        'Super Engraçado',
      ],
      heading: 'Aqui estão meus pontos fortes:',
    },
  }
  const renderHighlight = () => {
    return SCREEN_CONSTSANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ))
  }

  return (
    <div
      className="about-me-container screen-container fade-in"
      id={props.id || ''}
    >
      <div className="about-me-parent">
        <ScreenHeading
          title={'Quem eu sou'}
          subHeading={'Um pouco da minha história?'}
        />
        <div className="about-me-card">
          <div className="about-me-profile"></div>
          <div className="about-me-details">
            <span className="about-me-description">
              {SCREEN_CONSTSANTS.description}
            </span>
            <div className="about-me-highlights">
              <div className="highlight-heading">
                <span>{SCREEN_CONSTSANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
            </div>
            <div className="about-me-options">
              <a href="mailto:contato@thiagoteixeira.net">
                <button
                  className="btn primary-btn"
                  onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
                >
                  {' '}
                  Contrate-me{' '}
                </button>
              </a>
              <a href="ThiagoTeixeira.pdf" download="Curriculum Thiago.pdf">
                <button className="btn highlighted-btn">Meu Currículo</button>
              </a>{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
