import React from 'react'
import Typical from 'react-typical'
import './Profile.scss'

export default function Profile() {
  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="colz">
            <div className="colz-icon">
              <a
                href="https://www.linkedin.com/in/thiago--teixeira/"
                className="Link"
                alt=""
                title="linkedin"
                rel="noreferrer"
              >
                <i className="fa fa-linkedin-square"></i>
              </a>
              <a
                href="https://github.com/ThiagoTeixeiraTS"
                className="Link"
                alt=""
                title="github"
                rel="noreferrer"
              >
                <i className="fa fa-github-square"></i>
              </a>
              <a
                href="https://www.instagram.com/thiagotexera/"
                className="Link"
                alt=""
                title="instagram"
                rel="noreferrer"
              >
                <i className="fa fa-instagram"></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UCRb7-vWFGYwifJv8GT3BxOw"
                className="Link"
                alt=""
                title="youtube"
                rel="noreferrer"
              >
                <i className="fa fa-youtube-square"></i>
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=5512987024197"
                className="Link"
                alt=""
                title="whatsapp"
                rel="noreferrer"
              >
                <i className="fa fa-whatsapp"></i>
              </a>
            </div>
          </div>
          <div className="profile-details-name">
            <span className="primary-text">
              Olá, eu sou o
              <span className="highlighted-text"> Thiago Teixeira</span>
            </span>
          </div>
          <div className="profile-details-role">
            <span className="primary-text">
              <h1>
                {' '}
                <Typical
                  loop={Infinity}
                  steps={[
                    'Desenvolvedor .Net 💻 ',
                    1500,
                    'Desenvolvedor ReactJS 🎯',
                    1500,
                    'Desenvolvedor Full Stack 🖥️ ',
                    1500,
                  ]}
                  wrapper="p"
                />
              </h1>
              <span className="profile-role-tagline">
                Uma máquina consegue fazer o trabalho de 50 homens ordinários.
                Nenhuma máquina consegue fazer o trabalho de um homem
                extraordinário.
              </span>
            </span>
          </div>
          <div className="profile-options">
            <a href="mailto:contato@thiagoteixeira.net">
              <button className="btn primary-btn">Contrate-me</button>
            </a>
            <a href="ThiagoTeixeira.pdf" download="Curriculum Thiago.pdf">
              <button className="btn highlighted-btn">Meu Currículo</button>
            </a>
          </div>
        </div>
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  )
}
